import * as React from "react"
import Layout from "../components/Layout"
import ListMattress from "../components/ListMattress"
import Seo from "../components/Seo/Seo"

// markup
const Mattresses = () => {

  return (
    <Layout>

      <Seo
        title={null}
        description={null}
        image={null}
        pathname={'/mattresses'}
      />

      <main>
        <ListMattress className="bg-zone-3 py-12 px-4 xl:px-0" container={true} />
      </main>
    </Layout>
  )
}

export default Mattresses
