import React, { useState } from "react"
import { getVariables } from "../lib/utils"
import { useStaticQuery, graphql, Link, navigate } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import srcIconCheck from "../images/icon-check.svg"
import srcIconPlus from "../images/icon-plus.svg"

const ListMattress = ({ container = false, className = null }) => {

    const mattresses = useStaticQuery(graphql`
    query {
        allDatoCmsMattress(
            filter: {
                locale: {eq: "es"}, 
                location: {
                    websiteUrl: {eq: "es.mattress.zone"}
                }
            }, 
            sort: {
                fields: name, 
                order: ASC
                }
            ) {
            nodes {
                name
                slug
                location {
                    websiteUrl
                }
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "125", w: "250", colors: 10, cornerRadius: "10,10,10,10", mask: "corners"}
                        placeholder: BLURRED
                    )
                }
                id
            }
        }
        datoCmsTranslation(locale: {eq: "es"}) {
            details
            compare
        }
    }
    `)

    const variables = getVariables()
    const [toggleButton, setToggleButton] = useState(false)

    function getCheckedBoxes(chkboxName) {
        let checkboxes = document.getElementsByName(chkboxName)

        let checkboxesChecked = []
        // loop over them all
        for (let i = 0; i < checkboxes.length; i++) {
            // And stick the checked ones onto an array...
            if (checkboxes[i].checked) {
                checkboxesChecked.push(checkboxes[i])
            }
        }
        // Return the array if it is non-empty, or null
        return checkboxesChecked.length
    }

    function setUncheckedBoxes(chkboxName, disable) {
        let checkboxes = document.getElementsByName(chkboxName)
        // loop over them all
        for (let i = 0; i < checkboxes.length; i++) {
            // And stick the checked ones onto an array...
            if (checkboxes[i].checked) {
                //nothing
            } else {
                //attach disabled property
                checkboxes[i].disabled = disable
            }
        }
    }

    function checkboxesState() {
        let numbCheckboxes = getCheckedBoxes('selected_mattress')
        if (numbCheckboxes >= 3) {
            setUncheckedBoxes('selected_mattress', true)
        } else {
            setUncheckedBoxes('selected_mattress', false)
        }


        if (numbCheckboxes > 0) {
            setToggleButton(true)
        } else {
            setToggleButton(false)
        }
    }

    const handleSubmit = (event) => {
        event.preventDefault()
        // Custom logic for the form submission
        let mattresses = []
        let mattressesSlug = []
        let checkboxes = document.querySelectorAll('input[type=checkbox]:checked')

        for (let i = 0; i < checkboxes.length; i++) {
            mattresses.push(variables.mattress + '-' + checkboxes[i].value)
            mattressesSlug.push(checkboxes[i].value)
        }

        if (mattressesSlug.length < 2) {
            navigate(
                '/' + variables.mattress + '/' + mattresses.join('-vs-'),
                {
                    state: { mattressesSlug },
                }
            )
        } else {
            navigate(
                '/' + variables.comparer + '/' + mattresses.join('-vs-'),
                {
                    state: { mattressesSlug },
                }
            )
        }
    }

    const iconCheck = <img src={srcIconCheck} alt="✓" width="16" height="16" className="icon-check" />
    const wrapIconPlus = <div className="wrap-icon-plus"><img src={srcIconPlus} alt="+" width="16" height="16" title={variables.add_title} /></div>

    return (
        <div className={className}>
            <form className={` ${container ? 'container mx-auto' : null} `} onSubmit={handleSubmit} >
                <h4 className="text-lg text-zone-1 font-bold font-grotesk text-center mb-8">{variables.list_mattress_text}</h4>

                <div className="grid gap-6 grid-cols-1 md:gap-8 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4">
                    {mattresses.allDatoCmsMattress.nodes.map((mattress) => {
                        const image = getImage(mattress.mattressImage)

                        return (
                            <div className="item-mattress" key={`key-list-mattresses-${mattress.id}`}>
                                <input type="checkbox" id={`list-mattress-${mattress.id}`} name="selected_mattress" value={mattress.slug} onChange={checkboxesState} />
                                {iconCheck}
                                <label htmlFor={`list-mattress-${mattress.id}`}>
                                    {wrapIconPlus}
                                    <GatsbyImage image={image} alt={mattress.name} className="image" />
                                    <h3>{mattress.name}</h3>
                                    <Link to={`/${variables.mattress}/${variables.mattress}-${mattress.slug}`}>{mattresses.datoCmsTranslation.details}</Link>
                                </label>
                            </div>
                        )
                    }
                    )}
                </div>
                <div>
                    <button
                        type="submit"
                        className={`fixed right-0 left-0 bottom-20 mx-auto w-60 md:w-80 bg-zone-11 rounded-lg
                        text-white shadow-lg transition-all duration-300 ease-in border-none text-2xl 
                        uppercase z-50 hover:bg-zone-9 flex items-center justify-center py-2 px-4 font-bold 
                        ${toggleButton ? 'opacity-100 transform scale-100' : 'opacity-0 transform scale-0'} `}
                        id="btn-compare">
                        <span className="mr-4 text-4xl">&#8644;</span>
                        <span>{mattresses.datoCmsTranslation.compare}</span>
                    </button>
                </div>
            </form>
        </div>
    )
}

export default ListMattress